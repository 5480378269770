import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { ExchangeProfile } from '../types/exchange.type';

export type ExchangeProfileCreation = Omit<ExchangeProfile, 'id'> & {
  secret: string;
};

export const fetchExchangeProfiles = createAsyncThunk<
  ExchangeProfile[],
  void,
  {}
>('exchange/fetchProfiles', () =>
  axios
    .get(process.env.REACT_APP_API_KEY_URL)
    .then((body) => body.data as ExchangeProfile[])
);

export const addExchangeProfile = createAsyncThunk<
  ExchangeProfile[],
  ExchangeProfileCreation,
  {}
>('exchange/addProfile', (profile) =>
  axios
    .post(process.env.REACT_APP_API_KEY_URL, profile)
    .then((body) => body.data as ExchangeProfile[])
);
