import { AccessTokenJwt } from '@src/types/jwt';
import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

let accessToken: string | undefined = undefined;
let impersonationUserId: string | undefined = undefined;
let currentRequestInterceptor: number | undefined;

const getAccessToken = () => accessToken;

const updateAxiosInterceptor = () => {
  if (currentRequestInterceptor) {
    axios.interceptors.request.eject(currentRequestInterceptor);
  }

  currentRequestInterceptor = axios.interceptors.request.use((request) => {
    if (request.url?.startsWith(process.env.REACT_APP_API_HOST)) {
      request.headers.Authorization = `Bearer ${accessToken}`;
      if (impersonationUserId) {
        request.headers['x-sudo'] = `${impersonationUserId}`;
      }
    }
    return request;
  });
};

/**
 * Read access token from local storage and verify it validity.
 * If it's valid and not expired, return the jwt.
 *
 * @return `[string, AccessTokenJwt]`
 *  A tuple with token as first element and decoded payload as second element,
 *  or `undefined` if token is not found or invalid.
 */
const validateExistingLogin = () => {
  const token = window.localStorage.getItem('accessToken');
  if (token) {
    try {
      const jwt = jsonwebtoken.decode(token) as AccessTokenJwt;

      if (!jwt) {
        throw new Error(`Invalid JWT: ${jwt}`);
      }

      // if the token has expired, return undefined to indicate token is invalid
      if (jwt.exp <= Math.floor(Date.now() / 1000)) {
        console.warn(
          'Access token expired: ',
          new Date(jwt.exp * 1000).toISOString()
        );
        return undefined;
      }

      // @todo - verify signature of jwt token

      return [token, jwt] as const;
    } catch (e) {
      // most likely a jwt cannot be decoded into JSON.
      console.warn(e);
      return undefined;
    }
  }
  return undefined;
};

/**
 * This function should only be called during login or token refresh.
 * @param newAccessToken
 */
const updateAccessToken = (newAccessToken?: string) => {
  // Store access token in memory
  accessToken = newAccessToken;

  // Update axios interceptor with latest access token
  updateAxiosInterceptor();

  // Save/delete access token in local storage
  if (newAccessToken) {
    window.localStorage.setItem('accessToken', newAccessToken);
  } else {
    window.localStorage.removeItem('accessToken');
  }
};

const updateImpersonationUserId = (newImpersonationUserId?: string) => {
  impersonationUserId = newImpersonationUserId;
  updateAxiosInterceptor();
};

export default getAccessToken;
export { updateAccessToken, updateImpersonationUserId, validateExistingLogin };
