import axios from 'axios';
import { formatISO } from 'date-fns';

import { TradeRawData } from '../types/analytics.type';
import { Finance } from '../types/wallet.type';

export const fetchTradedData = async (
  startDate: number,
  endDate: number,
  userId?: string
) =>
  axios
    .get(
      `${
        process.env.REACT_APP_DASHBOARD_GRID_TRANSACTION_URL
      }?start=${formatISO(startDate, {
        representation: 'date',
      })}&end=${formatISO(endDate, { representation: 'date' })}${
        userId ? `&merchantExternalId=${userId}` : ''
      }`
    )
    .then((response) => response.data as TradeRawData);

export const fetchWallet = async (profileId: string) => {
  const body = await axios
    .get(
      `${process.env.REACT_APP_DASHBOARD_WALLET_URL}?merchantExchangeInfoExternalId=${profileId}`
    )
    .then((response) => response.data as Finance);
  return body;
};
