import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { DownlineInvoice } from '../types/agent.type';
import { UserData } from '../types/auth.type';
import { ExchangeProfile } from '../types/exchange.type';
import { fetchTradedData } from './services';
import * as services from './services';

type DownlineInvoiceResponse = Omit<DownlineInvoice, 'commission'>[];

const calculateCommission = (
  data: DownlineInvoiceResponse
): DownlineInvoice[] =>
  data.map((invoice) => ({
    ...invoice,
    commission: +((invoice.amount * invoice.agentPercentage) / 100).toFixed(2),
  }));

export const fetchDownlines = createAsyncThunk(
  'agent/fetch-downlines',
  async () => {
    const body = await axios
      .get(process.env.REACT_APP_AGENT_MERCHANT)
      .then((response) => response.data as UserData[]);
    return body;
  }
);

export const fetchDownlinesInvoices = createAsyncThunk(
  'agent/fetch-downlines-invoices',
  async ({ year, month }: { year: number; month: number }) => {
    const body = await axios
      .get(`${process.env.REACT_APP_AGENT_MERCHANT_INVOICE}/${year}/${month}`)
      .then((response) => response.data as DownlineInvoiceResponse)
      .then((data) => calculateCommission(data));
    return body;
  }
);

export const fetchInvestorTradeDataByDate = createAsyncThunk(
  'agent/fetch-investor-trade-data-by-date',
  ({ dateRange, userId }: { dateRange: [number, number]; userId: string }) =>
    fetchTradedData(dateRange[0], dateRange[1], userId)
);

export const fetchInvestorProfiles = createAsyncThunk(
  'agent/fetch-investor-profiles',
  async (investorId: string) => {
    const data = await axios
      .get(
        `${process.env.REACT_APP_API_KEY_URL}?merchantExternalId=${investorId}`
      )
      .then((body) => body.data as ExchangeProfile[]);
    return data;
  }
);

export const fetchWallet = createAsyncThunk(
  'agent/fetch-investor-wallet',
  async (profile: ExchangeProfile) => {
    const finance = await services.fetchWallet(profile.id);
    return {
      ...finance,
      name: profile.name,
    };
  }
);
