import { configureStore } from '@reduxjs/toolkit';
import createDebounce from 'redux-debounced';

import rootReducer from './rootReducer';

export const configureAppStore = (preloadedState?: any) =>
  configureStore({
    reducer: rootReducer,
    middleware: (middleware) => middleware().concat(createDebounce()),
    preloadedState,
  });
