import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ThunkFulfilledAction,
  ThunkPendingAction,
  ThunkRejectedAction,
} from '@src/types/redux';
import { startOfMonth } from 'date-fns';

import {
  fetchDownlines,
  fetchDownlinesInvoices,
  fetchInvestorProfiles,
  fetchInvestorTradeDataByDate,
  fetchWallet,
} from '../thunk/agent.thunk';
import { AgentState } from '../types/agent.type';

const initialState = (): AgentState => ({
  selectedMonth: startOfMonth(new Date()).getTime(),
  selectedInvestor: undefined,
  selectedInvestorProfiles: {
    status: 'idle',
    payload: undefined,
  },
  selectedInvestorWallets: {},
  downlines: {
    status: 'idle',
    payload: undefined,
  },
  downlinesInvoice: {
    status: 'idle',
    payload: undefined,
  },
  investorTradeData: {
    status: 'idle',
    payload: undefined,
  },
});

const slice = createSlice({
  name: 'agent',
  initialState: initialState(),
  reducers: {
    setSelectedMonth: (state, { payload }: PayloadAction<number>) => {
      state.selectedMonth = payload;
    },
    setSelectedInvestor: (
      state,
      { payload }: PayloadAction<{ id: string; name: string }>
    ) => {
      state.selectedInvestor = payload;
      state.investorTradeData.payload = undefined;
      state.selectedInvestorProfiles.payload = undefined;
      state.selectedInvestorWallets = {};
    },
  },
  extraReducers: {
    // fetch downlines (users)
    [fetchDownlines.pending.type]: (state) => {
      state.downlines.status = 'busy';
    },
    [fetchDownlines.fulfilled.type]: (
      state,
      { payload }: ThunkFulfilledAction<typeof fetchDownlines>
    ) => {
      state.downlines.status = 'idle';
      state.downlines.payload = payload;
    },
    [fetchDownlines.rejected.type]: (state) => {
      state.downlines.status = 'error';
    },

    // fetch downlines' invoices
    [fetchDownlinesInvoices.pending.toString()]: (state) => {
      state.downlinesInvoice.status = 'busy';
    },
    [fetchDownlinesInvoices.fulfilled.toString()]: (
      state,
      { payload }: ThunkFulfilledAction<typeof fetchDownlinesInvoices>
    ) => {
      state.downlinesInvoice.status = 'idle';
      state.downlinesInvoice.payload = payload;
    },
    [fetchDownlinesInvoices.rejected.toString()]: (state) => {
      state.downlinesInvoice.status = 'error';
    },

    // fetchInvestorTradeDataByDate
    [fetchInvestorTradeDataByDate.pending.type]: (state) => {
      state.investorTradeData.status = 'busy';
    },
    [fetchInvestorTradeDataByDate.fulfilled.type]: (
      state,
      { payload }: ThunkFulfilledAction<typeof fetchInvestorTradeDataByDate>
    ) => {
      state.investorTradeData.status = 'idle';
      state.investorTradeData.payload = payload;
    },
    [fetchInvestorTradeDataByDate.rejected.type]: (state) => {
      state.investorTradeData.status = 'error';
    },

    // fetchInvestorProfiles
    [fetchInvestorProfiles.pending.type]: (state) => {
      state.selectedInvestorProfiles.status = 'busy';
      // reset investor wallets
      state.selectedInvestorWallets = {};
    },
    [fetchInvestorProfiles.fulfilled.type]: (
      state,
      { payload }: ThunkFulfilledAction<typeof fetchInvestorProfiles>
    ) => {
      state.selectedInvestorProfiles.status = 'idle';
      state.selectedInvestorProfiles.payload = payload;
    },
    [fetchInvestorProfiles.rejected.type]: (state) => {
      state.selectedInvestorProfiles.status = 'error';
    },

    // fetch investor wallet
    [fetchWallet.pending.type]: (
      state,
      { meta: { arg: profile } }: ThunkPendingAction<typeof fetchWallet>
    ) => {
      state.selectedInvestorWallets[profile.id] = {
        status: 'busy',
        payload: undefined,
      };
    },
    [fetchWallet.fulfilled.type]: (
      state,
      {
        payload,
        meta: { arg: profile },
      }: ThunkFulfilledAction<typeof fetchWallet>
    ) => {
      state.selectedInvestorWallets[profile.id] = {
        status: 'idle',
        payload,
      };
    },
    [fetchWallet.rejected.type]: (
      state,
      { meta: { arg: profile } }: ThunkRejectedAction<typeof fetchWallet>
    ) => {
      state.selectedInvestorWallets[profile.id] = {
        status: 'error',
        payload: undefined,
      };
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const agentName = slice.name;
export const agentActions = slice.actions;
export const agentReducer = slice.reducer;
