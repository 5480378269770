import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { Grid } from '../types/grid.type';

export type GridCreation = Omit<
  Grid,
  'index' | 'id' | 'status' | 'gridSettings'
> & {
  exchangeProfileId: string;
};

export type CustomGridCreation = Omit<GridCreation, 'risk'> & {
  risk: 'Custom';
} & Required<Pick<Grid, 'gridSettings'>>;

// Add index to grid
// sort by descending order
const furnishGrids = (grids: Partial<Grid>[]) =>
  grids.map((grid, idx) => ({ index: idx + 1, ...grid })).reverse();

export const fetchGrids = createAsyncThunk('grid/fetch', () =>
  axios
    .get(process.env.REACT_APP_GRID_URL)
    .then((body) => furnishGrids(body.data as Partial<Grid>[]))
);

export const terminateGrid = createAsyncThunk(
  'grid/terminate',
  async (id: string) => {
    await axios.put(`${process.env.REACT_APP_GRID_URL}${id}/action/terminate`);
  }
);

export const addGrid = createAsyncThunk(
  'grid/add',
  (grid: GridCreation | CustomGridCreation) =>
    axios
      .post(process.env.REACT_APP_GRID_URL, grid)
      .then((body) => furnishGrids(body.data as Partial<Grid>[]))
);
