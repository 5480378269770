import themeConfig from '@configs/themeConfig';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LayoutState } from '../types/layout.type';

const MENU_COLLAPSE = 'menuCollapsed';

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem(MENU_COLLAPSE);
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const initialState: LayoutState = {
  isRTL: false,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: false,
  contentWidth: 'full',
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleRTL: (state, { payload }: PayloadAction<boolean>) => {
      state.isRTL = payload;
    },
    handleMenuCollapsed: {
      reducer: (state, { payload }: PayloadAction<boolean>) => {
        state.menuCollapsed = payload;
      },
      prepare: (payload: boolean) => {
        window.localStorage.setItem(MENU_COLLAPSE, String(payload));

        return {
          payload,
        };
      },
    },
    handleMenuHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.menuHidden = payload;
    },
    handleContentWidth: (state, { payload }: PayloadAction<string>) => {
      state.contentWidth = payload;
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const layoutName = slice.name;
export const layoutActions = slice.actions;
export const layoutReducer = slice.reducer;
