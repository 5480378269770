import { enumFrom, GetEnumType } from '@src/utility/enum-utils';

export const TradeStatus = enumFrom(
  'New',
  'Trading',
  'Cancelling',
  'Terminated'
);
export type TradeStatus = GetEnumType<typeof TradeStatus>;

export const RiskLevel = enumFrom(
  'Low',
  'MediumLow',
  'Medium',
  'MediumHigh',
  'High',
  'Custom'
);
export type RiskLevel = GetEnumType<typeof RiskLevel>;

export const Role = enumFrom('Admin', 'Agent', 'Investor').fallback('Investor');
export type Role = GetEnumType<typeof Role>;
