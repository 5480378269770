import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  updateAccessToken,
  updateImpersonationUserId,
} from '../../utility/auth/jwt.service';
import { AuthState, BasicUserData, UserData } from '../types/auth.type';

export const initialState: AuthState = {
  userData: undefined,
  impersonatingAs: undefined,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: {
      prepare: (payload: UserData & { accessToken: string }) => {
        updateAccessToken(payload.accessToken);
        return {
          payload: {
            ...payload,
            accessToken: undefined,
          },
        };
      },
      reducer: (state, { payload }: PayloadAction<UserData>) => {
        state.userData = payload;
      },
    },
    logout: {
      prepare: () => {
        updateAccessToken(undefined);
        updateImpersonationUserId(undefined);

        return {
          payload: undefined,
        };
      },
      reducer: (state) => {
        delete state.userData;
      },
    },
    impersonate: {
      prepare: (payload: BasicUserData) => {
        updateImpersonationUserId(payload.externalId);
        return { payload };
      },
      reducer: (state, { payload }: PayloadAction<BasicUserData>) => {
        state.impersonatingAs = payload;
      },
    },
    stopImpersonation: {
      prepare: () => {
        updateImpersonationUserId(undefined);
        return {
          payload: undefined,
        };
      },
      reducer: (state) => {
        delete state.impersonatingAs;
      },
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const authName = slice.name;
export const authActions = slice.actions;
export const authReducer = slice.reducer;
