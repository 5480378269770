import { createAsyncThunk } from '@reduxjs/toolkit';
import { Role } from '@src/types/enums';
import axios from 'axios';

import { UserData } from '../types/auth.type';

const transformUser = (user: any) =>
  ({
    ...user,
    role: Role.of(user.role),
    createdDateTime: new Date(user.createdDateTime).getTime(),
  } as UserData);

export const fetchAllUsers = createAsyncThunk('users/fetchAll', () =>
  axios
    .get(process.env.REACT_APP_ADMIN_MERCHANT_URL)
    .then((response) => (response.data as any[]).map(transformUser))
);

export const approveUser = createAsyncThunk(
  'users/approve',
  ({ userId, agentId }: { userId: string; agentId: string }) =>
    axios
      .put(
        `${process.env.REACT_APP_ADMIN_MERCHANT_URL}/${userId}/action/approve?agentMerchantExternalId=${agentId}`
      )
      .then((response) => (response.data as any[]).map(transformUser))
);
