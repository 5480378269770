import { createSlice } from '@reduxjs/toolkit';
import { FulfilledAction, RejectedAction } from '@src/types/redux';

import {
  addExchangeProfile,
  ExchangeProfileCreation,
  fetchExchangeProfiles,
} from '../thunk/exchange.thunk';
import { ExchangeProfile, ExchangeState } from '../types/exchange.type';

const initialState: ExchangeState = {
  profiles: {
    status: 'idle',
    payload: undefined,
  },
  creation: {
    status: 'idle',
  },
};

const slice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchExchangeProfiles.pending.toString()]: (state) => {
      state.profiles.status = 'busy';
    },
    [fetchExchangeProfiles.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<ExchangeProfile[]>
    ) => {
      state.profiles.status = 'idle';
      state.profiles.payload = payload;
    },
    [fetchExchangeProfiles.rejected.toString()]: (
      state,
      { error }: RejectedAction
    ) => {
      state.profiles.status = 'error';
      state.profiles.error = error;
    },

    [addExchangeProfile.pending.toString()]: (state) => {
      state.creation.status = 'busy';
    },
    [addExchangeProfile.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<ExchangeProfile[], ExchangeProfileCreation>
    ) => {
      state.creation.status = 'idle';
      state.profiles.payload = payload;
    },
    [addExchangeProfile.rejected.toString()]: (
      state,
      { error }: RejectedAction
    ) => {
      state.creation.status = 'error';
      state.creation.error = error;
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const exchangeName = slice.name;
export const exchangeActions = slice.actions;
export const exchangeReducer = slice.reducer;
