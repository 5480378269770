import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FulfilledAction,
  RejectedAction,
  ThunkFulfilledAction,
  ThunkPendingAction,
} from '@src/types/redux';
import { endOfMonth, startOfMonth } from 'date-fns';

import {
  fetchTradeDataByDate,
  fetchTradeDataByGridId,
} from '../thunk/analytics';
import { AnalyticsState, TradeRawData } from '../types/analytics.type';

const now = new Date();

const initialState: AnalyticsState = {
  gridId: undefined,
  dateRange: [startOfMonth(now).getTime(), endOfMonth(now).getTime()],
  tradeData: {
    status: 'idle',
    payload: undefined,
  },
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setSelectedGrid: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.gridId = payload;
    },
    setDateRange: (state, { payload }: PayloadAction<[number, number]>) => {
      state.dateRange = [payload[0], payload[1]];
    },
  },
  extraReducers: {
    // fetch trade data by grid ID
    [fetchTradeDataByGridId.pending.toString()]: (state) => {
      state.tradeData.status = 'busy';
    },
    [fetchTradeDataByGridId.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<TradeRawData>
    ) => {
      state.tradeData.status = 'idle';
      state.tradeData.payload = payload;
    },
    [fetchTradeDataByGridId.rejected.toString()]: (
      state,
      { error }: RejectedAction
    ) => {
      state.tradeData.status = 'error';
      state.tradeData.error = error;
    },

    // fetch trade data by date range
    [fetchTradeDataByDate.pending.type]: (
      state,
      { meta }: ThunkPendingAction<typeof fetchTradeDataByDate>
    ) => {
      state.tradeData.status = 'busy';
      state.dateRange = meta.arg;
    },
    [fetchTradeDataByDate.fulfilled.type]: (
      state,
      { payload }: ThunkFulfilledAction<typeof fetchTradeDataByDate>
    ) => {
      state.tradeData.status = 'idle';
      state.tradeData.payload = payload;
    },
    [fetchTradeDataByDate.rejected.type]: (
      state,
      { error }: RejectedAction
    ) => {
      state.tradeData.status = 'error';
      state.tradeData.error = error;
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const analyticsName = slice.name;
export const analyticsActions = slice.actions;
export const analyticsReducer = slice.reducer;
