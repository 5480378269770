import { createSlice } from '@reduxjs/toolkit';
import {
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from '@src/types/redux';

import { addGrid, fetchGrids, terminateGrid } from '../thunk/grid.thunk';
import { Grid, GridState } from '../types/grid.type';

const initialState: GridState = {
  grids: {
    status: 'idle',
    payload: undefined,
  },
  terminating: [],
  creation: {
    status: 'idle',
  },
};

const slice = createSlice({
  name: 'grid',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch grids
    [fetchGrids.pending.toString()]: (state) => {
      state.grids.status = 'busy';
    },
    [fetchGrids.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<Grid[]>
    ) => {
      state.grids.status = 'idle';
      state.grids.payload = payload;
    },
    [fetchGrids.rejected.toString()]: (state, { error }: RejectedAction) => {
      state.grids.status = 'error';
      state.grids.error = error;
    },

    // terminate grid
    [terminateGrid.pending.toString()]: (
      state,
      { meta: { arg: gridId } }: PendingAction<string>
    ) => {
      // push grid marked for termination into array
      // so that we know it's underway
      state.terminating.push(gridId);
    },
    [terminateGrid.fulfilled.toString()]: (
      state,
      { meta: { arg: gridId } }: FulfilledAction<Grid[], string>
    ) => {
      const grid = state.grids.payload?.find((grid) => grid.id === gridId);

      if (grid) {
        grid.status = 'Cancelling';
      }

      // remove ID from "terminating" status
      const terminatingIndex = state.terminating.indexOf(gridId);
      if (terminatingIndex > -1) {
        state.terminating.splice(terminatingIndex, 1);
      }
    },
    [terminateGrid.rejected.toString()]: (
      state,
      { meta: { arg: gridId } }: RejectedAction<string>
    ) => {
      // remove ID from "terminating" status
      const terminatingIndex = state.terminating.indexOf(gridId);
      if (terminatingIndex > -1) {
        state.terminating.splice(terminatingIndex, 1);
      }
    },

    // add grid
    [addGrid.pending.toString()]: (state) => {
      state.creation.status = 'busy';
    },
    [addGrid.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<Grid[]>
    ) => {
      state.creation.status = 'idle';
      state.grids.payload = payload;
    },
    [addGrid.rejected.toString()]: (state, { error }: RejectedAction) => {
      state.creation.status = 'error';
      state.creation.error = error;
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const gridName = slice.name;
export const gridActions = slice.actions;
export const gridReducer = slice.reducer;
