// ** Redux Imports
import { isActionType } from '@src/utility/redux-utils';
import { combineReducers } from 'redux';

import { agentName, agentReducer } from './slices/agent';
import { analyticsName, analyticsReducer } from './slices/analytics';
import {
  authName,
  authReducer,
  initialState as initialAuthState,
} from './slices/auth';
import { exchangeName, exchangeReducer } from './slices/exchange';
import { gridName, gridReducer } from './slices/grid';
import { layoutName, layoutReducer } from './slices/layout';
import {
  initialState as initialUsersState,
  usersName,
  usersReducer,
} from './slices/users';
import { walletName, walletReducer } from './slices/wallet';
import { AppState } from './types';

const appReducer = combineReducers<AppState>({
  [agentName]: agentReducer,
  [authName]: authReducer,
  [layoutName]: layoutReducer,
  [analyticsName]: analyticsReducer,
  [exchangeName]: exchangeReducer,
  [gridName]: gridReducer,
  [walletName]: walletReducer,
  [usersName]: usersReducer,
});

const rootReducer: typeof appReducer = (state, action) => {
  if (isActionType(action.type, 'auth/logout')) {
    return appReducer(undefined, action);
  } else if (isActionType(action.type, 'common/resetUserData')) {
    const toRetain = {
      auth: state?.auth ?? initialAuthState,
      users: state?.users ?? initialUsersState,
    };

    const resetState = appReducer(undefined, action);
    return appReducer(
      resetState ? { ...resetState, ...toRetain } : undefined,
      action
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
