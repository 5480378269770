import { createSlice } from '@reduxjs/toolkit';
import { FulfilledAction, RejectedAction } from '@src/types/redux';

import { approveUser, fetchAllUsers } from '../thunk/users.thunk';
import { UserData } from '../types/auth.type';
import { UsersState } from '../types/users';

export const initialState: UsersState = {
  merchants: {
    status: 'idle',
    payload: undefined,
  },
  approval: {
    status: 'idle',
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    // fetch all users
    [fetchAllUsers.pending.toString()]: (state) => {
      state.merchants.status = 'busy';
    },
    [fetchAllUsers.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<UserData[]>
    ) => {
      state.merchants.status = 'idle';
      state.merchants.payload = payload;
    },
    [fetchAllUsers.rejected.toString()]: (state, { error }: RejectedAction) => {
      state.merchants.status = 'error';
      state.merchants.error = error;
    },

    // approve user
    [approveUser.pending.toString()]: (state) => {
      state.approval.status = 'busy';
    },
    [approveUser.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<UserData[]>
    ) => {
      state.merchants.payload = payload;
      state.approval.status = 'idle';
    },
    [approveUser.rejected.toString()]: (state) => {
      state.approval.status = 'idle';
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const usersName = slice.name;
export const usersActions = slice.actions;
export const usersReducer = slice.reducer;
