import { createSlice } from '@reduxjs/toolkit';
import { FulfilledAction, RejectedAction } from '@src/types/redux';

import { fetchWallet } from '../thunk/wallet.thunk';
import { Finance, WalletState } from '../types/wallet.type';

const initialState: WalletState = {
  finance: {
    status: 'idle',
    payload: undefined,
  },
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchWallet.pending.toString()]: (state) => {
      state.finance.status = 'busy';
    },
    [fetchWallet.fulfilled.toString()]: (
      state,
      { payload }: FulfilledAction<Finance>
    ) => {
      state.finance.status = 'idle';
      state.finance.payload = payload;
    },
    [fetchWallet.rejected.toString()]: (state, { error }: RejectedAction) => {
      state.finance.status = 'error';
      state.finance.error = error;
    },
  },
});

export type ActionType = `${typeof slice.name}/${keyof typeof slice.actions}`;

export const walletName = slice.name;
export const walletActions = slice.actions;
export const walletReducer = slice.reducer;
