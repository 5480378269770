import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { TradeRawData } from '../types/analytics.type';
import { fetchTradedData } from './services';

export const fetchTradeDataByGridId = createAsyncThunk(
  'analytics/fetchTradeData',
  (gridId: string) =>
    axios
      .get(
        `${process.env.REACT_APP_DASHBOARD_GRID_TRANSACTION_URL}?masterGridExternalId=${gridId}`
      )
      .then((response) => response.data as TradeRawData)
);

export const fetchTradeDataByDate = createAsyncThunk(
  'analytics/fetchTradeDataByDate',
  (dateRange: [number, number]) => fetchTradedData(dateRange[0], dateRange[1])
);
