// ** React Imports
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type Theme =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark';

type Colours = {
  colors: {
    [key in Theme]: {
      light: string;
      main: string;
    };
  };
};

type SimplifiedColours = {
  [key in Theme]: string;
};

// ** Create Context
const ThemeColors = createContext<Colours>({} as any);

const ThemeContext = ({ children }: { children: ReactNode }) => {
  // ** State
  const [colors, setColors] = useState<Colours>({} as any);

  //** ComponentDidMount
  useEffect(() => {
    if (typeof window !== 'undefined') {
      //** Get variable value
      const getHex = (color: string) =>
        window.getComputedStyle(document.body).getPropertyValue(color).trim();

      //** Colors obj
      const obj = {
        primary: {
          light: getHex('--primary').concat('1a'),
          main: getHex('--primary'),
        },
        secondary: {
          light: getHex('--secondary').concat('1a'),
          main: getHex('--secondary'),
        },
        success: {
          light: getHex('--success').concat('1a'),
          main: getHex('--success'),
        },
        danger: {
          light: getHex('--danger').concat('1a'),
          main: getHex('--danger'),
        },
        warning: {
          light: getHex('--warning').concat('1a'),
          main: getHex('--warning'),
        },
        info: {
          light: getHex('--info').concat('1a'),
          main: getHex('--info'),
        },
        dark: {
          light: getHex('--dark').concat('1a'),
          main: getHex('--dark'),
        },
      };

      setColors({ colors: { ...obj } });
    }
  }, []);

  return (
    <ThemeColors.Provider value={{ ...colors }}>
      {children}
    </ThemeColors.Provider>
  );
};

const useThemeColours = (variant: 'main' | 'light' = 'main') => {
  const { colors } = useContext(ThemeColors);

  return Object.entries(colors)
    .map(([colour, shades]) => ({
      [colour]: shades[variant],
    }))
    .reduce(
      (val, curr) => ({
        ...val,
        ...curr,
      }),
      {}
    ) as SimplifiedColours;
};

export { ThemeColors, ThemeContext, useThemeColours };
