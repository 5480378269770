// ** React Imports
// ** Styles
import './ripple-button.scss';

import { Variant } from '@src/types/style';
import cx from 'classnames';
import { useEffect, useState } from 'react';
// ** Third Party Components
import { Button, ButtonProps, Spinner } from 'reactstrap';

export type MoreVariant = `${
  | 'flat'
  | 'outline'
  | 'gradient'
  | 'relief'}-${Variant}`;

export interface RippleButtonProps extends ButtonProps {
  color: Variant | MoreVariant | 'link';
  loading?: boolean;
  round?: boolean;
  iconOnly?: boolean;
}

const RippleButton = ({
  className = '',
  children,
  onClick,
  loading = false,
  round = false,
  iconOnly = false,
  block,
  ...rest
}: RippleButtonProps) => {
  // ** States
  const [mounted, setMounted] = useState(false);
  const [isRippling, setIsRippling] = useState(false);
  const [coords, setCoords] = useState({ x: -1, y: -1 });

  // ** Toggle mounted on mount & unmount
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // ** Check for coords and set ripple
  useEffect(() => {
    if (mounted) {
      if (coords.x !== -1 && coords.y !== -1) {
        setIsRippling(true);
        setTimeout(() => setIsRippling(false), 500);
      } else {
        setIsRippling(false);
      }
    }
  }, [coords]);

  // ** Reset Coords on ripple end
  useEffect(() => {
    if (mounted) {
      if (!isRippling) setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  return (
    <Button
      className={cx('waves-effect', {
        [className]: className,
        round,
        'btn-icon': iconOnly,
      })}
      onClick={(e) => {
        // @ts-ignore
        const rect = e.target.getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        if (onClick) {
          onClick(e);
        }
      }}
      block={block}
      {...rest}
    >
      <div
        className={cx('d-flex', 'align-items-center', {
          'justify-content-center': /btn-(\w+-)?block/.test(className) || block,
        })}
      >
        {loading ? (
          <span className={cx({ 'pr-1': !iconOnly })}>
            <Spinner size="sm" />
          </span>
        ) : null}
        {/* Do not show children if it's loading and iconOnly */}
        {!loading || !iconOnly ? children : null}
      </div>
      {isRippling ? (
        <span
          className="waves-ripple"
          style={{
            left: coords.x,
            top: coords.y,
          }}
        ></span>
      ) : null}
    </Button>
  );
};

export default RippleButton;
